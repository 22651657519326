import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';

import '../Footer.css';

function Footer() {
  return (
    <footer className="footer">
  <div className="footer-content">

    {/* Logo Section */}
    <div className="footer-section logo-cta">
      <img src="https://i.postimg.cc/8PnD1BDJ/Dev-Pursuit-Logo2.png" alt="DevPursuit Logo" className="footer-logo" />
    </div>

    {/* Information Section */}
    <div className="footer-section">
      <h4>About Us</h4>
      <p>Your go-to platform for MERN stack learning and software engineering excellence.</p>
      
      {/* Social Media Section */}
      <div className="social-media">
        <a href="https://facebook.com/devpursuit" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://instagram.com/devpursuit" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </div>

    {/* Contact Section */}
    <div className="footer-section">
      <h4>Contact Us</h4>
      <p>Email: info@devpursuit.com</p>
      {/* <p>Phone: (123) 456-7890</p> */}
    </div>

  </div>

  {/* Copyright */}
  <div className="footer-bottom">
    <p>&copy; {new Date().getFullYear()} DevPursuit. All rights reserved.</p>
    <Link to="/privacy-policy" className="privacy-link">Privacy Policy</Link>

    {/* <a href="/privacy-policy" target="_blank"  className="privacy-link"> Privacy Policy </a> */}

  </div>
</footer>

  );
}

export default Footer;

