import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import React Helmet
import Header from './components/Header';
import CourseDetails from './components/CourseDetails';
import SignUpForm from './components/SignUpForm';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import JavaScriptPractice from './components/JavaScriptPractice';
import Modal from './components/Modal';
import CongratsPage from './components/CongratsPage';
import CourseSyllabus from './components/CourseSyllabus';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasScrolledToSection, setHasScrolledToSection] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const courseSection = document.getElementById('course-syllabus-section');
      if (courseSection && !hasScrolledToSection) {
        const rect = courseSection.getBoundingClientRect();
        
        // Check if section is in view
        if (rect.top <= window.innerHeight) {
          setHasScrolledToSection(true); // Avoid re-triggering

          // Delay modal open with smooth transition
          setTimeout(() => {
            setModalOpen(true);
          }, 2000); // 2-second delay for smoother experience
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolledToSection]);

  return (
    <Router>
      <div className="App">
        {/* Global canonical tag for the home page */}
        <Helmet>
          <link rel="canonical" href="https://www.devpursuit.com" /> {/* Default canonical for homepage */}
        </Helmet>

        <Header />
        {isModalOpen && <Modal isOpen={isModalOpen} onClose={closeModal} />}
        
        <Routes>
          <Route path="/" element={
            <>
              {/* Homepage Title and Canonical Tag */}
              <Helmet>
                <title>DevPursuit | Home</title>
                <link rel="canonical" href="https://www.devpursuit.com" />
              </Helmet>
              <CourseDetails />
              <SignUpForm />
              <section id="course-syllabus-section">
                <CourseSyllabus />
              </section>
              <Testimonials />
            </>
          } />
          
          <Route path="/practice" element={
            <>
              {/* JavaScript Practice Title and Canonical Tag */}
              <Helmet>
                <title>JavaScript Practice | DevPursuit</title>
                <link rel="canonical" href="https://www.devpursuit.com/practice" />
              </Helmet>
              <JavaScriptPractice />
            </>
          } />
          
          <Route path="/congratulations" element={
            <>
              {/* Congratulations Page Title and Canonical Tag */}
              <Helmet>
                <title>Congratulations | DevPursuit</title>
                <link rel="canonical" href="https://www.devpursuit.com/congratulations" />
              </Helmet>
              <CongratsPage />
            </>
          } />
          
          <Route path="/privacy-policy" element={
            <>
              {/* Privacy Policy Page Title and Canonical Tag */}
              <Helmet>
                <title>Privacy Policy | DevPursuit</title>
                <link rel="canonical" href="https://www.devpursuit.com/privacy-policy" />
              </Helmet>
              <PrivacyPolicy />
            </>
          } />

          {/* Default route for 404 or any non-matching paths */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
