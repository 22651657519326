
import React from 'react';
import { Link } from 'react-router-dom';
import '../CongratsPage.css';

const CongratsPage = () => {
    return (
        <div className="congrats-container">
            {/* Content Section */}
            <div className="congrats-content">
                <h1 className="congrats-header">Congratulations!</h1>
                <p className="congrats-message">
                    You've successfully completed all the exercises. 🎉
                    Well done on your dedication and progress in learning JavaScript!
                </p>
                <p className="congrats-subtext">
                    At DevPursuit, we believe in continuous growth, and this is just the beginning!
                </p>

                {/* Call to Action Section */}
                <div className="call-to-action">
                    <h2>Stay Connected with Us!</h2>
                    <p>We'd love for you to be part of the DevPursuit community. Here’s how you can stay in touch:</p>

                    <ul>
                        <li>
                            <a href="https://www.devpursuit.com/newsletter" target="_blank" rel="noopener noreferrer">
                                Join our Newsletter for course updates and tech news
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/devpursuit" target="_blank" rel="noopener noreferrer">
                             Follow us on Instagram for all things tech
                            </a>
                        </li>
                        <li>
                            <a href="https://devpursuit.slack.com/archives/C07UN8YD6SX" target="_blank" rel="noopener noreferrer">
                             Join the DevPursuit Community on Slack
                            </a>
                        </li>
                    </ul>

                    <p>We’re excited to have you on this learning journey, and we can’t wait to see where it takes you!</p>
                </div>

                {/* Return to Home */}
                <div className="back-to-exercises">
                    <Link to="/">
                        <button className="return-button">Return to Home</button>
                    </Link>
                </div>
            </div>

            {/* Featured Image Section */}
            <div className="featured-image-container">
                <img 
                    src="https://cdn.gamma.app/hwqiyqnz3pr3zh3/generated-images/EJmSSsjngOBx_2xXBkSHc.jpg" 
                    alt="Celebration" 
                    className="featured-image"
                />
            </div>
        </div>
    );
};

export default CongratsPage;
